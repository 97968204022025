import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConfigBaseFilter, ConfigService, FilterListService, IconConfigBaseFilter, RenderFilter, RenderFilterIconValueList, RenderFilterSubValueList, RenderFilterValueList, SimpleConfigBaseFilter, SubRenderFilter, SubSimpleConfigBaseFilter } from 'gung-list';

export interface SjoborgRenderFilter extends RenderFilter {
  searchPlaceholder?: string,
  showEDIN?: boolean
}

export interface SjoborgRenderFilterValueList extends RenderFilterValueList {
  extraSearchTerms?: string[],
  EDIN?: string
}

@Injectable({
  providedIn: 'root'
})
export class SjoborgFilterListService<T> extends FilterListService<T> {
  getInitializedFilters(
    items: T[],
    configService: ConfigService<T>,
    routeSnapshot?: ActivatedRouteSnapshot
  ): RenderFilter[] {
    const configFilters = configService.getFilters(routeSnapshot);
    const result = [];

    configFilters.forEach(configFilter => {
      const newRenderFilter: SjoborgRenderFilter = {
        name: configFilter.getName(),
        type: configFilter.type,
        valueList: [],
        active: configFilter.isActiveFromStart(),
        hideItemCounter: configFilter.hideItemCounter(),
        isPostFilter: configFilter.isPostFilter(),
        excludedIds: [],
        sort: configFilter.sort?.bind(configFilter),
        sortField: configFilter.getSortField ? configFilter.getSortField() : undefined,
        customClass: configFilter.getCustomClass ? configFilter.getCustomClass() : undefined,
        disableSearch: configFilter.disableSearch,
        conceptGroupedPath: configFilter?.conceptGroupedPath,
        showTodayDate: configFilter?.showTodayDate,
        searchPlaceholder: configFilter.conceptGroupedPath === 'pim.Materialbeskrivning' ? 'SEARCH_MATERIAL_FILTER' : undefined
      };

      result.push(newRenderFilter);

      if (newRenderFilter.type === 'ConceptGroupedConfigBaseFilter') {
        newRenderFilter.conceptGroupedChoiceMap = {};
      }

      items.forEach(item => {
        if (this.filterIsSimpleConfigBaseFilter(configFilter)) {
          const configFilter2 = configFilter as SimpleConfigBaseFilter<T>;
          const itemOptionIds = configFilter2.getOptionIds(item);
          itemOptionIds.forEach(optionIdAny => {
            // Convert all option ids to strings
            const optionId = optionIdAny ? '' + optionIdAny : '';
            const optionIdWithoutCommas = optionId.replace(/,/g, '.');
            let value = newRenderFilter.valueList.find(optionValue => optionValue.valueId === optionIdWithoutCommas) as SjoborgRenderFilterValueList;

            if (!value) {
              value = {
                valueId: optionIdWithoutCommas,
                valueName: configFilter2.getOptionName(optionId, item),
                itemsIncluded: [configService.getItemId(item)],
                selected: false,
                itemCountTotal: 1,
                itemCountAfterFilter: 1,
                hide: false,
                sortIndex: configFilter2.getSortingIndex ? configFilter2.getSortingIndex(optionIdWithoutCommas) : null,
                extraSearchTerms: []
              };

              if (!value.valueName) {
                value.valueName = value.valueId;
              }

              if (this.filterIsIconConfigBaseFilter(configFilter)) {
                const configFilter4 = configFilter as IconConfigBaseFilter<T>;
                const icon = configFilter4.getIcon && configFilter4.getIcon(optionId, item);
                value.valueName = configFilter4.getOptionName(optionId, item);
                if (icon) {
                  const value4 = value as RenderFilterIconValueList;
                  value4.icon = icon;
                }
              }

              newRenderFilter.valueList.push(value);
            } else {
              value.itemsIncluded.push(configService.getItemId(item));
              value.itemCountTotal += 1;
              value.itemCountAfterFilter += 1;
            }

            if (this.filterIsSubSimpleConfigBaseFilter(configFilter)) {
              // Get sub values of the current value
              const configFilter3 = (configFilter as SubSimpleConfigBaseFilter<T>).subFilter;
              const subItemOptionIds = configFilter3.getOptionIds(item);
              const newSubRenderFilter = newRenderFilter as SubRenderFilter;
              // const idx = result.indexOf(newRenderFilter);
              newSubRenderFilter.subName = configFilter3.getName();
              const subValue = value as RenderFilterSubValueList;
              if (!subValue.subValueList) {
                subValue.subValueList = [];
              }
              subItemOptionIds.forEach(optionIdAny => {
                // Convert all option ids to strings
                const subOptionId = optionIdAny ? '' + optionIdAny : '';
                const subOptionIdWithoutCommas = subOptionId.replace(',', '.');
                let sub: RenderFilterValueList = subValue.subValueList.find(
                  optionValue => optionValue.valueId === optionIdWithoutCommas + '_' + subOptionIdWithoutCommas
                );
                if (!sub) {
                  sub = {
                    valueId: optionIdWithoutCommas + '_' + subOptionIdWithoutCommas,
                    valueName: configFilter3.getOptionName(subOptionId, item),
                    itemsIncluded: [configService.getItemId(item)],
                    selected: false,
                    itemCountTotal: 1,
                    itemCountAfterFilter: 1,
                    hide: false,
                    sortIndex: configFilter3.getSortingIndex
                      ? configFilter3.getSortingIndex(subOptionIdWithoutCommas)
                      : null
                  };

                  if (!sub.valueName) {
                    sub.valueName = sub.valueId;
                  }

                  subValue.subValueList.push(sub);
                } else {
                  sub.itemsIncluded.push(configService.getItemId(item));
                  sub.itemCountTotal += 1;
                  sub.itemCountAfterFilter += 1;
                }
              });
            }
          });
        }
      });


      // Build choice map of the available options in each filter option
      if (newRenderFilter.type === 'ConceptGroupedConfigBaseFilter') {
        for (const value of newRenderFilter.valueList) {
          const valueId: string = value.valueId.toString();

          if (!newRenderFilter.conceptGroupedChoiceMap[valueId]) {
            newRenderFilter.conceptGroupedChoiceMap[valueId] = {};
          }

          for (const concept of items) {
            const conceptId: string = configService.getItemId(concept);

            for (const [productId, productExtra] of Object.entries((concept as any)?.extra?.productValueMap || {})) {
              if (!newRenderFilter.conceptGroupedChoiceMap[valueId][conceptId]) {
                newRenderFilter.conceptGroupedChoiceMap[valueId][conceptId] = {};
              }
              newRenderFilter.conceptGroupedChoiceMap[valueId][conceptId][productId] = this.matchFilterOption(productExtra, value, newRenderFilter.conceptGroupedPath);
            }
          }
        }
      }
    });

    const res = this.getFilteredRenderFilters(result);
    this.sortRenderFilterValues(res);

    for (const filter of (res as SjoborgRenderFilter[])) {
      if (filter.conceptGroupedPath === 'pim.Materialbeskrivning') {
        filter.searchPlaceholder = 'SEARCH_MATERIAL_FILTER';
        filter.showEDIN = true;
        const config = configFilters.find(f => f.getName() === filter.name);


        for (const value of (filter.valueList as SjoborgRenderFilterValueList[])) {
          const id: string = value.valueId;
          const extra = config?.['valueExtraParamsMap']?.[id] || [];
          value.extraSearchTerms = [...extra];
          value.EDIN = config?.['valueEDINMap']?.[id];
        }
      }

    }

    return res;
  }
}
