import { Component } from '@angular/core';
import { GungNoImageUrl } from 'gung-common';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';

@Component({
  selector: 'lib-home-hero-banner-and-categories-template',
  templateUrl: './home-hero-banner-and-categories-template.component.html',
  styleUrl: './home-hero-banner-and-categories-template.component.scss'
})
export class HomeHeroBannerAndCategoriesTemplateComponent extends CustomPageTemplateComponent {
  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

  public getTextNoTranslate(text: { enabled: boolean; text: string; i18n?: {} }, textField = 'title') {
    if (text && !text.enabled) {
      return null;
    }
    if (text && text.text) {
      return text.text;
    }
    if (this.edit) {
      return 'INSERT_TEXT_HERE';
    }
    return '';
  }

  public isTextFilled(text: { enabled: boolean; text: string; i18n?: {} }, textField = 'title') {
    if (text && !text.enabled) {
      return false;
    }
    if (text && (text.text || text?.i18n?.[this.currentLang]?.[textField])) {
      return true;
    }
    return false;
  }
}

const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    heroElement: {
      link: {
        enabled: false,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 1400x1400
      },
      text: {
        enabled: true,
        text: 'Hero Header'
      }
    },
    heroTextSubheader: {
      link: {
        enabled: false,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Hero Subheader'
      }
    },
    preHeader: {
      link: {
        enabled: false,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'preHeader'
      }
    },
    Header: {
      link: {
        enabled: false,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Header'
      }
    },
    link1: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Link 1'
      }
    },
    link2: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Link 2'
      }
    },
    link3: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Link 3'
      }
    },
    link4: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Link 4'
      }
    },
    link5: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Link 5'
      }
    },
    category1: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Category 1'
      }
    },
    category2: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'category 2'
      }
    },
    category3: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'category 3'
      }
    },
    category4: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'category 4'
      }
    },
    category5: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'category 5'
      }
    },
    category6: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'category 6'
      }
    },
    sectionProducts: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'product ids'
      }
    },
    sectionProductsSize: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'product ids'
      }
    },
    productGroupHeader: {
      link: {
        enabled: false,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'News'
      }
    },
    bottomLink: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Shop now'
      }
    }
  }
};
