import { Component, Input } from '@angular/core';
import { FilterListSideFilterComponent } from 'gung-list';
import { SjoborgRenderFilter } from '../../services/sjoborg-filter-list.service';

@Component({
  selector: 'sjoborg-filter-list-side-filter',
  templateUrl: './sjoborg-filter-list-side-filter.component.html',
  styleUrl: './sjoborg-filter-list-side-filter.component.scss'
})
export class SjoborgFilterListSideFilterComponent extends FilterListSideFilterComponent {
  @Input()
  public renderFilter: SjoborgRenderFilter;

  filterBySearchTerm(searchTerm: string, items: any[]): any[] {
    return items.filter(item => {
      let hasHitAllTerms = true;
      const queryTerms = searchTerm.split(' ');
      const terms = !!item.valueName ? [this.translateService.instant(item.valueName), ...(item.extraSearchTerms || [])] : [];
      queryTerms.forEach(queryTerm => {
        const locatedTerm = terms.find(term => {
          if (term === null || term === undefined) {
            return false;
          }
          return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
        });

        hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
      });

      return hasHitAllTerms;
    });
  }
}
