import { Injectable } from '@angular/core';
import { AppState } from '../../state/state.module';
import { Store } from '@ngrx/store';
import { Metadata, TableRecord } from '../../state/metadata/types';
import { Observable, empty } from 'rxjs';
import { map, tap, filter, first } from 'rxjs';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  protected metadata: Metadata = {};

  constructor(protected store: Store<AppState>) {
    this.getMetadata().subscribe(d => (this.metadata = d));
  }

  protected getMetadata(): Observable<Metadata> {
    return this.store.select(state => state.metadata.metadata);
  }

  public getMetadataDirect(): Metadata {
    return this.metadata;
  }
  getMetadataCompleted(): Observable<void> {
    return this.store
      .select(state => state.metadata.metadata)
      .pipe(
        filter(metadata => !!metadata && Object.keys(metadata).length > 0),
        map(_ => {
          return;
        }),
        first()
      );
  }

  getMetadataTableList(tableName: string, companyIdentifier?: string): [TableRecord] {
    try {
      return this.metadata[tableName + (companyIdentifier ?? '')].list;
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetaDataTableMap(tableName: string, companyIdentifier?: string): { [key: string]: TableRecord } {
    try {
      return this.metadata[tableName + (companyIdentifier ?? '')].map;
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetadataValue(tableName: string, valueName: string, key: string, companyIdentifier?: string): string | undefined {
    return this.getMetadataValueInternal(this.metadata, tableName, valueName, key, companyIdentifier);
  }

  getMetadataValue$(tableName: string, valueName: string, key: string, companyIdentifier?: string): Observable<string> {
    return this.getMetadata().pipe(
      tap(metadata => (this.metadata = metadata)),
      map(metadata => this.getMetadataValueInternal(metadata, tableName, valueName, key, companyIdentifier))
    );
  }

  protected getMetadataValueInternal(
    metadata: Metadata,
    tableName: string,
    valueName: string,
    key: string,
    companyIdentifier?: string
  ): string | undefined {
    try {
      return this.resolve(valueName, metadata[tableName + (companyIdentifier ?? '')].map[key]);
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetadataPost(tableName: string, key: string, companyIdentifier?: string): any {
    return this.getMetadataPostInternal(this.metadata, tableName, key, companyIdentifier);
  }

  getMetadataPost$(tableName: string, key: string, companyIdentifier?: string): Observable<any> {
    return this.getMetadata().pipe(
      tap(metadata => (this.metadata = metadata)),
      map(metadata => this.getMetadataPostInternal(metadata, tableName, key, companyIdentifier))
    );
  }

  protected getMetadataPostInternal(metadata: Metadata, tableName: string, key: string, companyIdentifier?: string): any {
    try {
      return metadata[tableName + (companyIdentifier ?? '')].map[key];
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetadataValues(tableName: string, keyField: string, valueField?: string, companyIdentifier?: string): { [key: string]: TableRecord | string } {
    return this.getMetadataValuesInternal(this.metadata, tableName, keyField, valueField, companyIdentifier);
  }

  getMetaValueWithLang(tableName: string, key: string, currentLang: string, langKey = 'i18n', companyIdentifier?: string): string {
    if (!key) {
      return undefined;
    }
    if (!this.metadata[tableName + (companyIdentifier ?? '')]) {
      return undefined;
    }

    const metaElement = this.metadata[tableName + (companyIdentifier ?? '')].list.find(element => element.id === key);
    if (!metaElement) {
      return undefined;
    }

    // Fallback to english
    currentLang = currentLang || 'en';
    if (!metaElement?.[langKey]?.[currentLang]?.name) {
      // Fallback to name
      return metaElement.name;
    }

    return metaElement[langKey][currentLang].name;
  }

  getMetaValueKeyWithLang(tableName: string, tableKey: string, key: string, currentLang: string, langKey = 'i18n', companyIdentifier?: string): string {
    if (!key) {
      return undefined;
    }
    if (!this.metadata[tableName + (companyIdentifier ?? '')]) {
      return undefined;
    }

    const metaElement = this.metadata[tableName + (companyIdentifier ?? '')].list.find(element => element[tableKey] === key);
    if (!metaElement) {
      return undefined;
    }

    // Fallback to english
    currentLang = currentLang || 'en';
    if (metaElement?.[langKey]?.[currentLang]?.name) {
      return metaElement[langKey][currentLang].name;
    }

    // Fallback to name
    return metaElement.name || metaElement?.[langKey]?.[currentLang];
  }

  getMetadataValuesAsArray(
    tableName: string,
    keyField: string,
    valueField?: string,
    companyIdentifier?: string
  ): { key: string; value: TableRecord | string }[] {
    return this.toKeyValueArray(this.getMetadataValues(tableName, keyField, valueField, companyIdentifier));
  }

  getMetadataValues$(
    tableName: string,
    keyField: string,
    valueField?: string,
    companyIdentifier?: string
  ): Observable<{ [key: string]: TableRecord | string }> {
    return this.getMetadata().pipe(
      map(metadata => this.getMetadataValuesInternal(metadata, tableName, keyField, valueField, companyIdentifier))
    );
  }

  getMetadataValuesAsArray$(
    tableName: string,
    keyField: string,
    valueField?: string,
    companyIdentifier?: string
  ): Observable<{ key: string; value: TableRecord | string }[]> {
    return this.getMetadataValues$(tableName, keyField, valueField, companyIdentifier).pipe(map(data => this.toKeyValueArray(data)));
  }

  protected toKeyValueArray(data: {
    [key: string]: TableRecord | string;
  }): { key: string; value: TableRecord | string }[] {
    const keys = Object.keys(data);
    return keys.map(key => ({ key, value: data[key] }));
  }

  protected getMetadataValuesInternal(
    metadata: Metadata,
    tableName: string,
    keyField: string,
    valueField?: string,
    companyIdentifier?: string
  ): { [key: string]: TableRecord | string } {
    if (!metadata[tableName + (companyIdentifier ?? '')] || !metadata[tableName + (companyIdentifier ?? '')].list) {
      return {};
    }
    const res = metadata[tableName + (companyIdentifier ?? '')].list.reduce(
      (prev, curr) => ({
        ...prev,
        [this.resolve(keyField, curr)]: valueField && valueField !== '' ? this.resolve(valueField, curr) : curr
      }),
      {}
    );
    return res;
  }

  private resolve(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    const res = properties.reduce((prev, curr) => prev && prev[curr], obj);
    return res;
  }

  public formValidatorMetadata(table, metaId, metaDisplay, companyIdentifier?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const meta = this.getMetadataValue(table, metaDisplay, value, companyIdentifier);
      if (meta === undefined) {
        return { FORM_INVALID_METADATA_VALUE: true };
      }
      return null;
    };
  }
}
