import { Injectable } from '@angular/core';
import { ValidationResult, ValidationAction, ValidationRequest } from '../../models/quantity-validation';
import { TranslateService } from '@ngx-translate/core';
import { format, parse } from 'date-fns';
import { Availability } from '../../models/availability';
import { DeliveryDateService } from '../delivery-date/delivery-date.service';
import { Observable } from 'rxjs';
import { CalendarDate } from '../../models/calender-date';

@Injectable({
  providedIn: 'root'
})
export class CheckoutQuantityValidationService {
  enablePartialDeliveries = true;

  constructor(protected translateService: TranslateService) { }

  //  this.deliveryDateService.getDeliveryDates(this.form.get('levsattkod').value).pipe(first())

  public getLowerAvailabilityAction(
    validationResult: ValidationResult,
    validationRequest?: ValidationRequest
  ): ValidationAction {
    const availability = validationResult.availability.currentAvailability;
    return {
      id: 'LOWER_QUANTITY',
      description: this.translateService.instant('LOWER_QUANTITY') + ' -> ' + availability,
      callback(): void {
        validationResult.row.quantity = availability;
      }
    };
  }

  public getLowerAvailabilityFutureStockAction(
    validationResult: ValidationResult,
    validationRequest?: ValidationRequest
  ): ValidationAction {
    const availability = validationResult.availability.maxFutureStock;
    return {
      id: 'FUTURE_LOWER_QUANTITY',
      description: this.translateService.instant('AVAILABLE_IN_FUTURE_LOWER_QUANTITY') + ' -> ' + availability,
      callback(): void {
        validationResult.row.quantity = availability;
      }
    };
  }

  public getNOOPAction(validationResult: ValidationResult): ValidationAction {
    return {
      description: this.translateService.instant('NO_ACTION_SELECT_ACTION'),
      callback(): void {
        // Do nothing, used as first action when you want force users to select something
      }
    };
  }
  public getRemoveProductAction(
    validationResult: ValidationResult,
    validationRequest?: ValidationRequest
  ): ValidationAction {
    return {
      id: 'REMOVE',
      description: this.translateService.instant('REMOVE_PRODUCT'),
      callback(): void {
        validationResult.row.quantity = 0;
      }
    };
  }

  public getQtyValidationResult(validationRequest: ValidationRequest): ValidationResult {
    const validationResult: ValidationResult = {
      row: validationRequest.row,
      product: validationRequest.product,
      availability: validationRequest.availability,
      valid: true,
      actions: []
    };

    if (!this.shouldValidate(validationRequest)) {
      return validationResult;
    }

    const availabilityValue = this.getAvailabilityValue(validationRequest);
    if (availabilityValue < validationRequest.row.quantity) {
      validationResult.valid = false;
      validationResult.description =
        this.translateService.instant('NOT_ENOUGH_AVAILABILITY') +
        ' (' +
        validationResult.row.quantity +
        '>' +
        availabilityValue +
        ')';
      if (availabilityValue > 0) {
        // Reduce quantity not relevant if no quantity exists
        validationResult.actions.push(this.getLowerAvailabilityAction(validationResult, validationRequest));
      }
      validationResult.actions.push(this.getRemoveProductAction(validationResult, validationRequest));
      validationResult.selectedAction = validationResult.actions[0];
    }
    return validationResult;
  }

  protected shouldValidate(validationRequest: ValidationRequest): boolean {
    return true;
  }

  protected getAvailabilityValue(validationRequest: ValidationRequest): number {
    return validationRequest.availability.currentAvailability;
  }

  public transformAvailabilityDates(
    sourceAvailabilityMap: any,
    firstDate: Date,
    calendarValidDeliveryDates: CalendarDate[]
  ): any {
    const firstDateStr = format(firstDate || new Date(), 'yyyy-MM-dd');
    const firstDateWithoutTime = parse(firstDateStr, 'yyyy-MM-dd', firstDate);

    let validDatesMapped = null;
    if (calendarValidDeliveryDates) {
      validDatesMapped = {};
      for (const theDate of calendarValidDeliveryDates) {
        if (theDate.valid) {
          validDatesMapped[format(theDate.date, 'yyMMdd')] = true;
        }
      }
    }

    const remapped = {};

    // Somewhat randomly chosen considering only the fact that jeeves calendar cycles at lest every two weeks
    const MAX_ATTEMPTS = 35;

    for (const key of Object.keys(sourceAvailabilityMap)) {
      const futureDate = parse(key, 'yyMMdd', new Date());
      let newkey = key;
      if (futureDate <= firstDateWithoutTime) {
        newkey = format(firstDateWithoutTime, 'yyMMdd');
      }

      if (validDatesMapped) {
        let attempts = 0;
        while (!validDatesMapped[newkey] && attempts < MAX_ATTEMPTS) {
          var theDate = parse(newkey, 'yyMMdd', new Date());
          var newDate = new Date(theDate.getTime() + 1000 * 60 * 60 * 24);
          newkey = format(newDate, 'yyMMdd');
          attempts++;
        }
      }

      remapped[newkey] = sourceAvailabilityMap[key];
    }
    return remapped;
  }

  public getFutureAvailability(
    availability: Availability,
    requiredQty: number,
    calendarValidDeliveryDates: CalendarDate[] = null
  ): { qty: number; date: Date } {
    const remapped = this.transformAvailabilityDates(
      availability.extra.availabilities,
      null,
      calendarValidDeliveryDates
    );

    let result = { qty: 0, date: new Date(0) };
    for (const key of Object.keys(remapped)) {
      const value = remapped[key];
      if (requiredQty > result.qty) {
        if (value >= requiredQty) {
          const futureDate = parse('20' + key, 'yyyyMMdd', new Date());
          result = {
            qty: parseInt(value, 10),
            date: futureDate
          };
        }
      }
    }
    return result;
  }

  public getFutureAvailabilityPlan(
    firstDate: Date,
    availability: Availability,
    requiredQty: number,
    calendarValidDeliveryDates: CalendarDate[] = null
  ): { qty: number; date: Date }[] {
    const result = [];
    let leftToAllocate = requiredQty;

    // Transform the list of availabilities according to current calendar (and selected date)
    const remapped = this.transformAvailabilityDates(
      availability.extra.availabilities,
      firstDate,
      calendarValidDeliveryDates
    );

    for (const key of Object.keys(remapped)) {
      const value = remapped[key];

      if (leftToAllocate > 0) {
        const futureDate = parse(key, 'yyMMdd', new Date());

        const allocation = Math.min(leftToAllocate, value);
        leftToAllocate = leftToAllocate - allocation;
        const element = {
          qty: allocation,
          date: futureDate
        };
        result.push(element);
      }
    }

    return result;
  }
}
