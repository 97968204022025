import { Injectable } from "@angular/core";
import { GungNavbarService, GungShopMenu } from "gung-standard";

@Injectable({
  providedIn: 'root'
})
export class SjoborgNavbarService extends GungNavbarService {
  assortmentMenuLevel = 3;

  getAssortmentWithLevels(assortments): GungShopMenu[] {
    return [
      this.buildAssortmentNavigation(assortments, 0, this.getProductListBaseRoute()+'/', this.assortmentMenuLevel),
      { link: '/quick-products', heading: 'QUICK_SEARCH' },
      { link: '/news', heading: 'NEWS' }
    ];
  }
}