import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigBaseFilter, SimpleConfigBaseFilter } from 'gung-list';
import { Assortment, AssortmentRecursiveExtended, CloudPimFiltersService, GungBase, ItemProperties, MetadataService, PimNumberFilter, Product } from 'gung-standard';

export class SjoborgsItemTypeFilterPathField extends SimpleConfigBaseFilter<Product> {
  itemValue;

  currentLang = this.translateService.currentLang;

  conceptGroupedPath: string;
  type: string;

  valueEDINMap: { [s: string]: string } = {};
  valueExtraParamsMap: { [s: string]: string[] } = {};

  constructor(
    protected filterDisplayName: string,
    protected filterTitle: string,
    protected path: string,
    protected notFoundString: string,
    protected translateService?: TranslateService,
    protected metadataService?: MetadataService,
    protected tableName?: string,
    protected valueName?: string,
    protected multiValues?: boolean,
    protected isRange?: boolean,
    protected translationPath?: string,
    protected splitString?: string
  ) {
    super();
    if (this.isRange) {
      this.type = 'RangeConfigBaseFilter';
    } else if (this.multiValues) {
      this.type = 'ConceptGroupedConfigBaseFilter';
      this.conceptGroupedPath = path;
    } else {
      this.type = 'SimpleConfigBaseFilter';
    }
  }

  getName(): string {
    return !!this.filterDisplayName ? this.filterDisplayName : this.filterTitle;
  }

  getOptionIds(item: GungBase): string[] {
    this.itemValue = undefined;
    if (item.hasOwnProperty('product')) {
      // Concept detail Filter
      item = (item as any).product;
    }
    if (item === undefined || item === null) {
      return [this.notFoundString];
    }
    let valuesAsArray = [];
    const splitPath = ['extra', ...this.path.split('.')];
    const reducedValue = this.getReduceValueObject(splitPath, item);

    if (reducedValue === undefined || reducedValue === null) {
      return [this.notFoundString];
    } else if (!this.multiValues) {
      if (reducedValue && reducedValue.properties && reducedValue.properties.sorting && reducedValue.code) {
        // nested values (used in sorting)
        valuesAsArray = [reducedValue.code];
      } else if (Array.isArray(reducedValue)) {
        valuesAsArray = reducedValue.map(v => {
          let res = v;
          if (v.hasOwnProperty('description')) {
            res = v.description || this.notFoundString;
          }
          return res;
        });
      } else if (reducedValue.description) {
        // PIM metadata
        valuesAsArray = [reducedValue.description];
      } else {
        if (!!this.splitString) {
          valuesAsArray = [...reducedValue.split(this.splitString)];
        } else {
          valuesAsArray = [reducedValue];
        }
      }
    } else {
      if (Array.isArray(reducedValue)) {
        valuesAsArray = reducedValue.map(v => {
          let res = v;
          if (v.hasOwnProperty('description')) {
            res = v.description || this.notFoundString;
            this.valueEDINMap[res] = v.properties.E_DIN;
            this.valueExtraParamsMap[res] = [v?.properties?.SW_S];
          }
          return res;
        });
      } else if (reducedValue.description) {
        // PIM metadata
        valuesAsArray = [reducedValue.description];
      } else {
        valuesAsArray = [reducedValue];
      }
    }
    this.itemValue = reducedValue;
    const mappedValues = valuesAsArray.map(value => {
      if (typeof value === 'string' || value instanceof String) {
        value = value.replace(/^\s+|\s+$/g, '');
      }
      if (!value) {
        return this.notFoundString;
      } else {
        if (this.valueName && typeof value === 'object' && Object.keys(value).indexOf(this.valueName) >= 0) {
          return value[this.valueName];
        } else {
          return value;
        }
      }
    });
    //    console.log("mappedValues "+item.id+" - "+this.filterTitle,mappedValues);
    return [...new Set<any>(mappedValues)];
  }

  getTranslatedDescription(obj) {
    let translatedDescriptionString = 'translatedDescription';
    if (
      obj &&
      obj.translateProperties &&
      obj.translateProperties[this.currentLang] &&
      obj.translateProperties[this.currentLang][translatedDescriptionString] &&
      obj.translateProperties[this.currentLang][translatedDescriptionString] !== ''
    ) {
      return obj.translateProperties[this.currentLang][translatedDescriptionString];
    }

    return null;
  }

  getOptionName(key: string): string {
    if (!key) {
      return '';
    }
    if (this.translationPath) {
      let varToTranslate = this.itemValue;
      if (varToTranslate instanceof Array) {
        const findTranslateInArray = varToTranslate.find(v => v.description === key);
        if (findTranslateInArray) {
          varToTranslate = findTranslateInArray;
        }
      }
      const tranlsatedValue = this.getReduceValueObject(this.translationPath.split('.'), varToTranslate);
      if (!!tranlsatedValue) {
        return tranlsatedValue;
      }
    }
    if (!this.tableName || !this.valueName) {
      return key;
    }
    if (
      this.itemValue &&
      this.valueName &&
      typeof this.itemValue === 'object' &&
      Object.keys(this.itemValue).indexOf(this.valueName) >= 0
    ) {
      return this.itemValue[this.valueName];
    }
    return this.metadataService.getMetadataValue(this.tableName, this.valueName, key);
  }

  getSortingIndex(optionId?: string): string {
    if (Array.isArray(this.itemValue)) {
      const option = this.itemValue.find(value => {
        return value.description === optionId;
      });
      if (option?.properties?.sorting) {
        return option.properties.sorting;
      }
    } else if (this.itemValue?.properties?.sorting) {
      return this.itemValue.properties.sorting; // sorting index
    }
    return;
  }

  getReduceValueObject(splitPath, item): any {
    const translatedDescription = this.getTranslatedDescription(item);
    if (translatedDescription) {
      return translatedDescription;
    }
    if (splitPath.indexOf('i18n') > -1) {
      splitPath.splice(splitPath.indexOf('i18n') + 1, 0, this.currentLang);
    }
    const reducedValue = splitPath.reduce((oldValue, currentValue) => {
      if (!oldValue) {
        return oldValue;
      }
      if (oldValue[currentValue]) {
        return oldValue[currentValue];
      } else {
        return null;
      }
    }, item);
    if (
      !reducedValue ||
      (typeof reducedValue === 'object' &&
        Object.keys(reducedValue).length === 0 &&
        reducedValue.constructor === Object)
    ) {
      // Empty Object found
      return null;
    }
    return reducedValue;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SjoborgCloudPimFiltersService extends CloudPimFiltersService {
  getFilters(
    assortment: Assortment,
    multiValues?: boolean,
    assortmentLeaf?: AssortmentRecursiveExtended
  ): ConfigBaseFilter<Product>[] {
    const filters: ConfigBaseFilter<Product>[] = [];
    if (!!assortment && (assortment.extra.itemProperties || assortment.extra.skuProperties)) {
      let properties: ItemProperties[] = assortment.extra.itemProperties || assortment.extra.skuProperties;
      if (assortmentLeaf && this.assortmentService.isGroupedAssortment(assortmentLeaf)) {
        properties = assortmentLeaf.extra.itemProperties;
      }
      for (const property of properties) {
        if (/* property.isDisplay &&  */ property.isFilter) {
          // const isRange = property.path === 'pim.import_indrediameter'; // REMOVE THIS AND UNCOMMENT NEXT LINE
          const isRange = property.filterType && property.filterType === 'RANGE';

          const field = property.path.split('.')[1];
          const translationKey = property.translationKey;
          const displayName = property.displayName;

          // Check if translation exists
          const undefinedString = 'NO_' + translationKey + '_DEFINED';
          const undefinedTranslation =
            this.translateService.instant(undefinedString) === undefinedString ? 'UNDEFINED' : undefinedString;

          if (property.type === 'number') {
            filters.push(new PimNumberFilter(translationKey, field, multiValues));
          } else if (property.type === 'metadata') {
            if (property.metaReference) {
              filters.push(
                new SjoborgsItemTypeFilterPathField(
                  displayName,
                  translationKey,
                  property.path,
                  undefinedTranslation,
                  this.translateService,
                  this.metadataService,
                  property.metaReference.table,
                  property.metaReference.field,
                  multiValues,
                  isRange,
                  undefined,
                  this.splitString
                )
              );
            } else if (property.metadata && property.metadata.split('.').length === 2) {
              const splitmeta = property.metadata.split('.');
              const metaTable = splitmeta[0];
              const metaField = splitmeta[1];
              filters.push(
                new SjoborgsItemTypeFilterPathField(
                  displayName,
                  translationKey,
                  property.path,
                  undefinedTranslation,
                  this.translateService,
                  this.metadataService,
                  metaTable,
                  metaField,
                  multiValues,
                  isRange,
                  undefined,
                  this.splitString
                )
              );
            } else {
              let translationPath = property.filterValueField || 'description';
              if (translationPath.indexOf('.') > -1) {
                const splittedPath = translationPath.split('.');
                if (translationPath.indexOf('translateProperties') > -1) {
                  splittedPath.splice(splittedPath.indexOf('translateProperties') + 1, 0, this.currentLang);
                }
                translationPath = splittedPath.join('.');
              }
              filters.push(
                new SjoborgsItemTypeFilterPathField(
                  displayName,
                  translationKey,
                  property.path,
                  undefinedTranslation,
                  this.translateService,
                  this.metadataService,
                  null,
                  null,
                  multiValues,
                  isRange,
                  translationPath,
                  this.splitString
                )
              );
            }
          } else {
            filters.push(
              new SjoborgsItemTypeFilterPathField(
                displayName,
                translationKey,
                property.path,
                undefinedTranslation,
                this.translateService,
                null,
                null,
                null,
                multiValues,
                isRange,
                undefined,
                this.splitString
              )
            );
          }
        }
      }
    }

    return filters;
  }
}
