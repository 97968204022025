import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigBaseFilter, CustomHostDirective, PimTemplateProperties } from 'gung-list';
import { filter, first, forkJoin, mergeMap, Observable, of, Subscription, switchMap } from 'rxjs';
import { Product } from '../../models';
import { AssortmentTreeImageSelectionService } from '../../services/assortment-tree-image-selection/assortment-tree-image-selection.service';
import { AssortmentTreeNavigationConfigService } from '../../services/assortment-tree-navigation-config/assortment-tree-navigation-config.service';
import { AssortmentRecursive, AssortmentService } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';
import { CloudPimFiltersService } from '../../services/cloud-pim-filters/cloud-pim-filters.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { GungStringConverterService } from '../../services/gung-string-converter.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { PriceService } from '../../services/price/price.service';
import { ProductService } from '../../services/products/product.service';
import { ProductConcept } from '../concept-detail-v2/concept-detail-v2.component';
import { GungDownloadUrl } from 'gung-common';
import { Title } from '@angular/platform-browser';
import { GungTitleService } from '../../services/gung-title.service';

export interface BreadcrumbItem {
  label: string;
  url: string;
  urlParts: string[];
}

export interface ItemProperties {
  isDisplay: boolean;
  isDisplayDetails: boolean;
  isFilter: boolean;
  filterType: string;
  path: string;
  translationKey: string;
  type: string;
  metadata: any;
  metaReference: any;
  filterValueField?: string;
  displayName?: string;
}

interface AssortmentRecursiveExtended extends AssortmentRecursive {
  documents?: any[];
  description?: string;
  productType?: string;
}

@Component({
  selector: 'lib-assortment-tree-navigation',
  templateUrl: './assortment-tree-navigation.component.html',
  styleUrls: ['./assortment-tree-navigation.component.css']
})
export class AssortmentTreeNavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(CustomHostDirective)
  viewChild: QueryList<CustomHostDirective>;

  currentLang: string;

  userAssortments: AssortmentRecursive;
  currentAssortment: AssortmentRecursiveExtended;
  products: Product[];
  productId: string;

  breadcrumbMenu: BreadcrumbItem[] = [];
  type: string;
  filters: ConfigBaseFilter<any>[] = [];

  routerSubscription: Subscription;

  assortmentLeaf: AssortmentRecursiveExtended;

  protected toGung = id => GungStringConverterService.toGungString(id);
  protected fromGung = id => GungStringConverterService.fromGungString(id);
  protected getProductName = productObj => productObj.extra?.i18n?.[this.currentLang]?.assortmentName || productObj.name;

  public userRoles: string[];
  public tileOriginal: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected assortmentService: AssortmentService,
    protected productService: ProductService,
    protected translateService: TranslateService,
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected assortmentTreeNavigationConfigService: AssortmentTreeNavigationConfigService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected titleService: Title,
    protected gungTitleService: GungTitleService
  ) {
    this.tileOriginal = this.gungTitleService.gungDefaultTitle || this.gungTitleService.getDefaultTitle()
  }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
  }

  ngAfterViewInit() {
    this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          this.userRoles = user.roles;
          return this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first());
        })
      )
      .subscribe(result => {
        this.userAssortments = result;
        // this.setParent(this.userAssortments.children, []);

        this.route.url.subscribe((segments: UrlSegment[]) => {
          if (this.viewChild && this.viewChild.length !== 0) {
            // Clear views already rendered from previous navigation
            const containerRef = this.viewChild.last.viewContainerRef;
            containerRef.clear();
          }
          this.type = undefined;
          this.products = undefined;
          this.productId = undefined;
          this.currentAssortment = this.userAssortments;
          this.breadcrumbMenu = this.createBreadcrumbs(segments); // Get current assortment
          this.getListType().subscribe(type => {
            this.filters = this.getFilters(type === 'PRODUCTS_GROUPED');
            this.type = type;
            this.getDocuments();
            if (
              this.currentAssortment &&
              this.currentAssortment.extra.i18n &&
              this.currentAssortment.extra.i18n[this.currentLang]
            ) {
              this.currentAssortment.description =
                this.currentAssortment.extra.i18n[this.currentLang].description || '';
            }
            if (
              this.currentAssortment &&
              !this.currentAssortment.description &&
              this.currentAssortment.extra.i18n &&
              this.currentAssortment.extra.i18n[this.currentLang]
            ) {
              this.currentAssortment.description =
                this.currentAssortment.extra.i18n[this.currentLang].assortmentDescription || '';
            }
            if (this.currentAssortment && !this.currentAssortment.description && this.currentAssortment.extra.pim) {
              this.currentAssortment.description = this.currentAssortment.extra.pim.description || '';
            }
            switch (type) {
              case 'ASSORTMENTS':
                this.renderLayout(this.assortmentTreeNavigationConfigService.getAssortmentLayout(), {
                  assortmentChildren: this.currentAssortment.children
                });
                break;
              case 'CONCEPTS':
                this.renderLayout(
                  this.assortmentTreeNavigationConfigService.getConceptLayout(),
                  // {subProducts: this.products}
                  {
                    conceptChildren: this.products,
                    filters: this.filters,
                    dynamicColumns: this.getHeaderDynamicTranslationKeys(),
                    pimOptions: this.currentAssortment.extra.pim
                  }
                );
                break;
              case 'CONCEPT':
                this.renderLayout(this.assortmentTreeNavigationConfigService.getConceptDetailLayout(), {
                  subProducts: this.products,
                  filters: this.filters,
                  dynamicColumns: this.getHeaderDynamicTranslationKeys(),
                  pimOptions: this.currentAssortment.extra.pim
                });
                break;
              case 'PRODUCTS':
                this.renderLayout(this.assortmentTreeNavigationConfigService.getProductsLayout(), {
                  products: this.products,
                  filters: this.filters,
                  dynamicColumns: this.getHeaderDynamicTranslationKeys(),
                  pimOptions: this.currentAssortment.extra.pim
                });
                break;
              case 'PRODUCTS_GROUPED':
                this.renderLayout(this.assortmentTreeNavigationConfigService.getConceptLayout(), {
                  conceptChildren: this.products,
                  filters: this.filters
                });
                break;
              case 'PRODUCT':
                this.renderLayout(this.assortmentTreeNavigationConfigService.getProductDetailLayout(), {
                  productId: this.products[0].id,
                  currentAssortment: this.currentAssortment
                });
                break;
            }
          });
        });
      });

    // Maintain scrool after apply filter
    const yScrollStack = [];
    let lastPoppedUrl;
    this.routerSubscription = this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url.split('?')[0] !== lastPoppedUrl) {
          yScrollStack.push(window.scrollY);
          lastPoppedUrl = this.router.url.split('?')[0];
        }
      } else if (ev instanceof NavigationEnd && yScrollStack.length > 0) {
        if (ev.url.split('?')[0] === lastPoppedUrl) {
          window.scrollTo(0, yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
        lastPoppedUrl = undefined;
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  protected createBreadcrumbs(urlSegment: UrlSegment[]): BreadcrumbItem[] {
    const breadcrumbs: BreadcrumbItem[] = [];
    let url = '';
    let urlParts: string[] = [];
    let label;

    // Init
    url = '/articles';
    urlParts = [url + '/'];

    label = this.getProductName(this.currentAssortment);
    breadcrumbs.push({ label, url, urlParts });
    let lastId = this.currentAssortment.id;
    for (const segment of urlSegment) {
      const segmentPath = this.toGung(segment.path);
      const breadcrumb = {
        label: segment.path + '',
        urlParts: [url + '/', segment.path],
        url: (url += '/' + segment.path) + ''
      };

      const currentAssortment = this.currentAssortment.children.find(c => c.id === segmentPath);
      // if (!currentAssortment && urlSegment[urlSegment.length - 1] !== segment= {
      if (!currentAssortment && this.currentAssortment.assortment) {
        // ASSORTMENT CHILE NOT FOUND GO TO PARENT - NEW FEATURE
        this.router.navigate(urlParts);
        return;
      } else if (!currentAssortment && !this.currentAssortment.assortment && urlSegment[urlSegment.length - 1] !== segment) {
        // CHECK IF THE CONCEPT HAVE THE CHIL (ASSORTMENT DONT SHOW THE CONCEPT CHILDS)
        this.productService.getProduct(lastId || this.currentAssortment.id).pipe(first()).subscribe(c => {
          if (c?.productType === 'concept') {
            if ((c as ProductConcept).subProductIds.includes(segmentPath)) {
              this.productService.getProduct(segmentPath).pipe(first()).subscribe(p => {
                if (p) {
                  breadcrumb.label = this.getProductName(p);
                  const idx = this.breadcrumbMenu.findIndex(b => b.label === segmentPath);
                  if (idx > -1) {
                    this.breadcrumbMenu.splice(idx, 1, { ...this.breadcrumbMenu[idx], label: breadcrumb.label });
                  }
                } else {
                  // PRODUCT CHILD NOT FOUND GO TO PARENT - NEW FEATURE
                  this.router.navigate(breadcrumb.urlParts);
                }
              });
            } else {
              // CONCEPT CHILD NOT FOUND GO TO PARENT - NEW FEATURE
              this.router.navigate(breadcrumb.urlParts);
            }
          }
        })
      }

      if (currentAssortment) {
        // check card https://trello.com/c/cHXIOAIu/259-when-clicking-a-product-within-a-concept-the-product-page-does-not-appear
        this.currentAssortment = currentAssortment;
      } else {
        lastId = segmentPath;
      }

      if (currentAssortment && currentAssortment.id === segmentPath) {
        if (currentAssortment.assortment || (currentAssortment.children && currentAssortment.children.length > 0)) {
          this.assortmentLeaf = currentAssortment;
        }
        breadcrumb.label = this.getProductName(this.currentAssortment);
      }
      if (segment === urlSegment[urlSegment.length - 1] && !currentAssortment?.assortment) {
        // Last element is Product as part of concept if not in assortment recursive
        this.productId = segmentPath;
      }
      // if (segment !== urlSegment[urlSegment.length - 1] && !currentAssortment?.assortment) {
      // This elemente is a concept
      // }
      breadcrumbs.push(breadcrumb);
    }

    return breadcrumbs;
  }

  protected getListType(): Observable<string> {
    /*if (this.productId && this.currentAssortment.id !== this.productId) {
      // ASSORTMENT/CONCEPT/PRODUCT NOT FOUND GO TO PARENT - NEW FEATURE
      const idx = this.breadcrumbMenu?.findIndex(b => b.label === this.productId);
      if (idx > -1) {
        this.router.navigate(this.breadcrumbMenu[idx - 1].urlParts);
        return of(null);
      }
    } else*/ if (this.currentAssortment.assortment || this.currentAssortment.children.length > 0) {
      if (this.currentAssortment.children.find(c => c.assortment || c.children.length > 0)) {
        // Assortment
        return forkJoin([
          of('ASSORTMENTS'),
          // this.productService.getProductsByAssortmentUnfiltered(this.currentAssortment.id)
          of(null)
        ]).pipe(
          switchMap(([type, products]) => {
            return of(type);
          })
        );
      }

      const childPimCollection = this.currentAssortment.extra.pim
        ? this.currentAssortment.extra.pim.childPimCollection
        : '';

      // An empty assortment containing no products should be renderad as such
      if (this.currentAssortment.assortment && this.currentAssortment.children.length === 0) {
        this.products = [];
        return of('PRODUCTS');
      }

      if (this.assortmentService.isGroupedAssortment(this.currentAssortment)) {
        return forkJoin([
          of('PRODUCTS_GROUPED'),
          this.productService.getProductsByAssortmentExpanded(this.currentAssortment.id).pipe(first()),
          this.productService.getProductsByAssortmentUnfiltered(this.currentAssortment.id)
        ]).pipe(
          switchMap(([type, products, concepts]) => {
            const productsGrouped = this.getGroupedProducts(products, concepts, this.currentAssortment);
            this.products = productsGrouped;
            return of(type);
          })
        );
      } else if (childPimCollection === 'pim-item') {
        return forkJoin([
          of('PRODUCTS'),
          this.productService.getProductsByAssortment(this.currentAssortment.id).pipe(first())
        ]).pipe(
          switchMap(([type, products]) => {
            this.products = products;
            return of(type);
          })
        );
      } else if (childPimCollection === 'pim-item-concept') {
        if (this.assortmentTreeNavigationConfigService.ignoreFilterOnConcept) {
          const productIds = this.currentAssortment.children.map(c => c.id);
          return forkJoin([
            of('CONCEPTS'),
            this.productService.getProductsByAssortmentUnfiltered(this.currentAssortment.id)
          ]).pipe(
            switchMap(([type, products]) => {
              // Filter out the products not in children
              const productsFilered = products.filter(p => productIds.includes(p.id));
              return forkJoin([of(type), of(productsFilered)]);
            }),
            switchMap(([type, products]) => {
              this.products = products;
              return of(type);
            })
          );
        }
        return forkJoin([
          of('CONCEPTS'),
          this.productService.getProductsByAssortment(this.currentAssortment.id).pipe(first())
        ]).pipe(
          switchMap(([type, products]) => {
            this.products = products;
            return of(type);
          })
        );
      } else {
      }

      return this.productService.getProduct(this.currentAssortment.children[0].id).pipe(
        first(),
        mergeMap(product => {
          if (product && product.productType) {
            if (product.productType === 'singleDimension' || product.productType === 'multiDimension') {
              const productIds = this.currentAssortment.children.map(c => c.id);
              return forkJoin([
                of('PRODUCTS'),
                // this.productService.getProductsByIds(productIds).pipe(first())
                this.productService.getProductsByAssortment(this.currentAssortment.id).pipe(first())
              ]);
            }
            if (product.productType === 'concept') {
              const productIds = this.currentAssortment.children.map(c => c.id);
              return forkJoin([
                of('CONCEPTS'),
                // this.productService.getProductsByIds(productIds).pipe(first())
                this.productService.getProductsByAssortmentUnfiltered(this.currentAssortment.id)
              ]).pipe(
                switchMap(([type, products]) => {
                  // Filter out the products not in children
                  const productsFilered = products.filter(p => productIds.includes(p.id));
                  return forkJoin([of(type), of(productsFilered)]);
                })
              );
            }
          }
          return forkJoin([of('ASSORTMENTS'), of(null)]);
        }),
        switchMap(([type, products]) => {
          this.products = products;
          return of(type);
        })
      );
    }
    // CONCEPTS OR PRODUCT
    // Added assortment to indicate node being part of the structure (ie not a leaf)
    if (!this.currentAssortment.assortment && this.currentAssortment.children.length === 0) {
      const request: Observable<Product>[] = [this.productService.getProduct(this.currentAssortment.id).pipe(first())];
      if (this.productId) {
        request.push(this.productService.getProduct(this.productId).pipe(first()));
      }
      return forkJoin(request).pipe(
        first(),
        mergeMap(([productFromAssortment, productFromId]) => {
          const product: Product = productFromId /* || productFromAssortment */;
          if (this.productId && product && this.productId === product?.id) {
            // breadcrumbs - Rename product id to product name
            this.breadcrumbMenu[this.breadcrumbMenu.length - 1].label = this.getProductName(product);
            this.breadcrumbMenu = JSON.parse(JSON.stringify(this.breadcrumbMenu));
          }
          if (product && product.productType && product.productType === 'concept' && this.productId === product?.id) {
            this.currentAssortment = product as any;
            const subProductIds = (product as any).subProductIds;
            return forkJoin([of('CONCEPT'), this.productService.getProductsByIds(subProductIds).pipe(first())]);
          }
          if (product) {
            if (this.productId && productFromAssortment?.productType !== 'concept') {
              this.currentAssortment = this.assortmentLeaf || productFromAssortment || (productFromId as any);
            } else {
              this.currentAssortment = productFromAssortment || this.assortmentLeaf || (productFromId as any);
            }
            return forkJoin([of('PRODUCT'), of([product])]);
          }
          // ERROR TYPE: No product, concept = Assortment without children
          // ASSORTMENT/CONCEPT/PRODUCT NOT FOUND GO TO PARENT - NEW FEATURE
          const idx = this.breadcrumbMenu?.findIndex(b => b.label === this.productId);
          if (idx > -1) {
            this.router.navigate(this.breadcrumbMenu[idx - 1].urlParts);
          }
          return forkJoin([of('ASSORTMENTS'), of(null)]);
        }),
        switchMap(([type, products]) => {
          this.products = products;
          return of(type);
        })
      );
    }
    return of('');
  }

  getHeaderDynamicTranslationKeys() {
    const headerDynamicTranslationKeys: PimTemplateProperties[] = [];
    // Dynamic column
    if (this.currentAssortment.extra.skuProperties || this.currentAssortment.extra.itemProperties) {
      for (const skuProperty of (this.currentAssortment.extra.skuProperties ||
        this.currentAssortment.extra.itemProperties) as PimTemplateProperties[]) {
        if (skuProperty.isDisplay || skuProperty.isDisplayGrid || skuProperty.isDisplayList) {
          headerDynamicTranslationKeys.push(skuProperty);
        }
      }
    }
    return headerDynamicTranslationKeys;
  }

  getFilters(multiValues?: boolean): ConfigBaseFilter<Product>[] {
    return this.cloudPimFiltersService.getFilters(this.currentAssortment, multiValues);
  }

  setTitle() {
    if (this.titleService) {
      if (this.currentAssortment && this.type !== 'PRODUCT' && this.type !== 'CONCEPT') {
        const title = this.breadcrumbMenu?.map(b => b.label)?.slice(1)?.join(' > ');
        this.titleService.setTitle(`${title} - ${this.tileOriginal}`);
      } else if (this.type === 'CONCEPT') {
        // const title = this.translateService.instant('CONCEPT');
        this.titleService.setTitle(`${this.currentAssortment?.name} - ${this.tileOriginal}`);
      } else if (this.type === 'PRODUCT') {
        // const title = this.translateService.instant('PRODUCT');
        this.titleService.setTitle(`(${this.products?.[0]?.id}) ${this.products?.[0]?.name} - ${this.tileOriginal}`);
      }
    }

  }

  renderLayout(layoutComponent: Type<any>, data: any = {}): void {
    if (!this.viewChild || this.viewChild.length === 0 || !layoutComponent) {
      return;
    }
    this.setTitle();
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.last.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as any;
    if (data.assortmentChildren) {
      typedComponent.assortmentChildren = data.assortmentChildren;
    }
    if (data.conceptChildren) {
      typedComponent.conceptChildren = data.conceptChildren;
    }
    if (data.subProducts) {
      typedComponent.subProducts = data.subProducts;
    }
    if (data.products) {
      typedComponent.products = data.products;
    }
    if (data.filters) {
      typedComponent.filters = data.filters;
    }
    if (data.productId) {
      typedComponent.productId = data.productId;
    }
    if (data.currentAssortment) {
      typedComponent.currentAssortment = data.currentAssortment;
    }
    if (data.dynamicColumns) {
      typedComponent.dynamicColumns = data.dynamicColumns;
    }
    if (data.pimOptions) {
      typedComponent.pimOptions = data.pimOptions;
    }
    this.changeDetectorRef.detectChanges();
  }

  getGroupedProducts(products: Product[], concepts: Product[], assortment: AssortmentRecursiveExtended): Product[] {
    const result = [];

    const includedKeys = [];
    const includedValueKeys = [];

    assortment.extra.itemProperties.forEach(itemProperty => {
      const splittedPath = itemProperty.path.split('.');
      includedKeys.push(splittedPath[0]);
      includedValueKeys.push(splittedPath[1]);
    });

    const productsMap: {
      [productId: string]: Product;
    } = {};

    products.forEach(product => {
      productsMap[product.id] = product;
    });

    if (concepts && concepts.length > 0) {
      concepts.forEach(concept => {
        const modifiedConcept = {
          ...concept
        };
        const mappedExtra: { [s: string]: any } = {};

        if ((concept as any).subProductIds) {
          (concept as any).subProductIds.forEach(subProductId => {
            const product = productsMap[subProductId];
            if (!product) {
              return;
            }


            if (mappedExtra.productValueMap) {
              mappedExtra.productValueMap[product.id] = {};
            } else {
              mappedExtra.productValueMap = {};
              mappedExtra.productValueMap[product.id] = {};
            }


            Object.keys(product.extra).forEach(key => {
              if (!includedKeys.includes(key)) {
                return;
              }

              if (typeof product.extra[key] === 'object') {
                mappedExtra[key] = mappedExtra[key] || {};
                mappedExtra.productValueMap[product.id][key] = mappedExtra.productValueMap[product.id][key] || {};

                if (product.extra && product.extra[key]) {
                  Object.keys(product.extra[key]).forEach(valueKey => {
                    if (!includedValueKeys.includes(valueKey)) {
                      return;
                    }
                    mappedExtra[key][valueKey] = mappedExtra[key][valueKey] || [];
                    mappedExtra.productValueMap[product.id][key][valueKey] = mappedExtra.productValueMap[product.id][key][valueKey] || [];

                    if (Array.isArray(product.extra[key][valueKey])) {
                      (product.extra[key][valueKey] as any[]).forEach(val => {
                        mappedExtra[key][valueKey].push(val);
                        mappedExtra.productValueMap[product.id][key][valueKey].push(val);
                      });
                    } else {
                      mappedExtra[key][valueKey].push(product.extra[key][valueKey]);
                      mappedExtra.productValueMap[product.id][key][valueKey].push(product.extra[key][valueKey]);
                    }
                  });
                }
              } else {
                mappedExtra[key] = mappedExtra[key] || [];
                mappedExtra.productValueMap[product.id][key] = mappedExtra.productValueMap[product.id][key] || [];

                if (Array.isArray(product.extra[key])) {
                  (product.extra[key] as any[]).forEach(val => {
                    mappedExtra[key].push(val);
                    mappedExtra.productValueMap[product.id][key].push(val);
                  });
                } else {
                  mappedExtra[key].push(product.extra[key]);
                  mappedExtra.productValueMap[product.id][key].push(product.extra[key]);
                }
              }
            });
          });
        }

        modifiedConcept.extra = {
          ...modifiedConcept.extra,
          ...mappedExtra
        };

        result.push(modifiedConcept);
      });
    }

    return result;
  }

  changeSelectedImage(url: string) {
    this.assortmentTreeImageSelectionService.setSelectedImageUrl(url);
  }

  getDocuments() {
    if (this.currentAssortment && this.currentAssortment.extra.documents) {
      this.currentAssortment.documents = [];
      const documents: { name: string; url: string; extra: { [s: string]: any } }[] = [];

      for (const doc of this.currentAssortment.extra.documents) {
        let name: string;
        if (doc.i18n && doc.i18n[this.currentLang]) {
          name = doc.i18n[this.currentLang].documentType;
        }
        if (!name && doc.extra.i18n && doc.extra.i18n[this.currentLang]) {
          name = doc.extra.i18n[this.currentLang].documentType;
        }
        if (!name) {
          name = doc.name;
        }
        documents.push({
          name: name || 'TECHINCAL_CHARACTERISTICS_CATALOG',
          url: GungDownloadUrl(doc),
          extra: doc.extra
        });
      }
      this.currentAssortment.documents = documents;
    }
  }
}
