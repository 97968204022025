<div class="gung-container" *ngIf="loginRequired || (!isLoading && !errorOccured); else noApp">
  @if(!loginRequired){
    <lib-gung-navbar></lib-gung-navbar>
  }

  <div class="main container">
      <router-outlet></router-outlet>
      <div class="mockroles-warning">
        <lib-mockroles-warning></lib-mockroles-warning>
      </div>
      
      <lib-gung-notification></lib-gung-notification>
      
      <div *ngIf="cookiesEnabled">
        <lib-gung-cookie-consent></lib-gung-cookie-consent>
      </div>
  </div>

@if(!loginRequired){
  <ng-template [libCustomHostDynamic]="footerComponent" [inputs]="{}" [outputs]="{}" />
}


</div>
<ng-template #noApp>
<div class="wrapper d-flex align-items-center justify-content-center m-5">
  <div *ngIf="isLoading && !errorOccured">
    <i class="fa fa-spinner fa-spin fa-7x"></i>
  </div>
  <div *ngIf="errorOccured">
    <h1 class="text-center">An error occured, please log out and contact your supplier</h1>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" (click)="logout()" translate>LOGOUT</button>
    </div>
  </div>
</div>

</ng-template>