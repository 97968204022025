<div class="{{divClass}}">
  <table class="table {{tableClass}}" [class.attribute-table]="!removeAttributeTable">
    <tbody *ngIf="!collapse; else collapsed">
      <tr *ngFor="let item of productDetailsTable">
        <ng-container *ngIf="!!item.value || showEmpty">
          <th>
            {{ item.title | uppercase | translate }}
          </th>
          <td>
            <span [innerHTML]="item.value | safeHtml"></span>
          </td>
        </ng-container>
      </tr>
    </tbody>
    <ng-template #collapsed>
      <tbody>
        <tr *ngFor="let item of productDetailsTable | slice: 0:collapse">
          <ng-container *ngIf="!!item.value || showEmpty">
            <th>
              {{ item.title | uppercase | translate }}
            </th>
            <td>
              <span [innerHTML]="item.value | safeHtml"></span>
            </td>
          </ng-container>
        </tr>
        <tr *ngFor="let item of productDetailsTable | slice: collapse" [class.collapse]="isCollapsed">
          <ng-container *ngIf="!!item.value || showEmpty">
            <th>
              {{ item.title | uppercase | translate }}
            </th>
            <td>
              <span [innerHTML]="item.value | safeHtml"></span>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="productDetailsTable.length > 5">
          <td colspan="2" class="p-0">
            <button
              type="button"
              class="btn btn-link p-0"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="collapseExample"
            >
              <span *ngIf="isCollapsed" translate> SHOW_MORE </span>
              <span *ngIf="!isCollapsed" translate> HIDE </span>
            </button>
          </td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>
