import { Injectable } from '@angular/core';
import { LoginConfigService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class SjoborgLoginConfigService extends LoginConfigService {
  getVideoBackground(): string {
    return 'https://cdn2.gung.io/sjoborgs/Sjöborgs Hero.mov';
  }
}
