import { Component } from '@angular/core';
import { ConceptTreeListComponent } from 'gung-standard';

@Component({
  selector: 'sjoborg-concept-tree-list',
  templateUrl: './sjoborg-concept-tree-list.component.html',
  styleUrl: './sjoborg-concept-tree-list.component.scss'
})
export class SjoborgConceptTreeListComponent extends ConceptTreeListComponent {

}
